<template>
  <TeacherAuth></TeacherAuth>
</template>

<script>
import TeacherAuth from '../user/components/teacherAuth'
export default {
  name: "authentication.vue",
  data(){
    return {

    }
  },
  components:{
    TeacherAuth
  },
  created(){
  },
  methods:{
  }
}
</script>

<style scoped lang="scss">
</style>
